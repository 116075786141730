export const SQUARE = 'square';
export const CIRCLE = 'circle';
export const TRIANGLE = 'triangle';

export const MAX_ANGLE = 30;
export const MAX_POWER = 20;
export const MAP_SIZE = 8;
export const MAP_LEVELS = 7;

export const ITEM_SIZE = 40;
